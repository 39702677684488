import React, { useEffect, useRef, useState } from 'react';
import NavButton from './NavButton';
import usePin from '../../../react-hooks/usePin';
import useLocalStorage from '../../../react-hooks/useLocalStorage';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

const HistoryButton = ({ user }) => {
  const { t } = useAureliaI18n();
  const isPinFeatureEnabled =
    user?.instance?.settings?.instanceGeneral?.enablePinFeature || false;
  const [isPinned, togglePin, allPins] = usePin();
  const [history, setHistory] = useLocalStorage('sio-router-history', []);
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const clearHistory = () => {
    setHistory([]);
  };

  const getPosition = () => {
    return buttonRef.current.getBoundingClientRect();
  };

  return (
    <NavButton
      title={t('history.title')}
      onClick={() => setMenuOpen(!menuOpen)}
      ref={buttonRef}
    >
      <div>
        <a data-toggle="dropdown">
          <i className="fa fa-clock-rotate-left fa-sm"></i>
        </a>
        {menuOpen && (
          <ul
            className="dropdown-menu"
            style={{
              position: 'fixed',
              zIndex: 9999,
              top: `${getPosition().bottom + 6}px`,
              left: `${getPosition().left}px`,
              display: 'block',
              transform: 'translate(-60%, 0px)',
            }}
          >
            {isPinFeatureEnabled &&
              allPins.map((item, index) => (
                <li key={index}>
                  <a href={item?.href}>
                    <i className="fa fa-thumbtack"></i> {item?.title}
                  </a>
                </li>
              ))}
            {isPinFeatureEnabled && allPins.length > 0 && (
              <li role="separator" className="divider"></li>
            )}
            {history.map((item, index) => (
              <li key={index}>
                <a href={item.href}>{item.title}</a>
              </li>
            ))}
            <li role="separator" className="divider"></li>
            <li>
              <a onClick={clearHistory}>{t('sio.clear-list')}</a>
            </li>
          </ul>
        )}
      </div>
    </NavButton>
  );
};

export default HistoryButton;
